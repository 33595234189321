<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog
      ref="dialogEl"
      as="div"
      class="relative z-[999]"
      :initial-focus="dialogEl"
      @close="showMenu"
    >
      <div class="fixed inset-0 top-header-top-with-discount">
        <div class="h-full items-center justify-center text-center">
          <TransitionChild
            as="template"
            enter="transition duration-250 ease-out"
            enter-from="-translate-x-full opacity-0"
            enter-to="translate-x-0 opacity-100"
            leave="transition duration-250 ease-in"
            leave-from="translate-x-0 opacity-100"
            leave-to="-translate-x-full opacity-0"
            @after-enter="onAfterEnter"
          >
            <DialogPanel
              class="relative flex h-full w-full transform flex-col overflow-auto overflow-x-hidden bg-white text-left align-middle shadow-xl transition-all"
            >
              <div class="container max-md:max-w-full">
                <LazyCommonAppHeaderSearchMobile
                  @setSearchLength="onSearchLength"
                  @result-item-click="closeModal"
                  @goToSearchPage="goToSearchPageHandlerLocal"
                />
              </div>
              <LazyCommonAppHeaderBurgerMenu
                v-show="menuVisible"
                class="flex-auto"
                @eventRedirect="closeModal"
              />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from "@headlessui/vue";

const dialogEl = ref();

const menuVisible = ref(true);

const { goToSearchPageHandler } = useGoToSearchPage();

const { lg2AndUp } = useAppBreakpoints();

const props = defineProps<{ modelValue: boolean; logoSrc: string }>();
const emit = defineEmits(["update:modelValue"]);
const isOpen = computed({
  get() {
    return props.modelValue;
  },
  set(value: boolean) {
    emit("update:modelValue", value);
  },
});

watch(lg2AndUp, () => {
  if (lg2AndUp.value) {
    closeModal();
  }
});

watch(lg2AndUp, () => {
  if (lg2AndUp.value) {
    closeModal();
  }
});

watch(
  () => props.modelValue,
  () => {
    if (!props.modelValue) {
      closeModal();
    }
  },
);

function closeModal() {
  isOpen.value = false;
  menuVisible.value = true;
}

function onAfterEnter() {
  setTimeout(() => {
    const inertEl = document.querySelector("[inert]");
    if (inertEl) {
      inertEl.removeAttribute("inert");
    }
  }, 0);
}

function showMenu() {
  menuVisible.value = true;
}

function onSearchLength(value: string) {
  menuVisible.value = !value;
}

function goToSearchPageHandlerLocal(searchValue: string) {
  goToSearchPageHandler(searchValue);
  closeModal();
}
</script>
