<template>
  <div
    v-if="footer.leftColumn?.content"
    class="ui-text-m text-white [&_p]:ui-text-m space-y-5"
    @click="contentLink"
    v-html="footer.leftColumn.content"
  />
</template>

<script setup lang="ts">
const { footer } = useGeneralInternalStore();
const contentLink = useContentLink;
</script>
