<template>
  <TransitionRoot
    appear
    :show="isOpen"
    as="template"
    @afterLeave="generalStore.setSitePopup(null)"
  >
    <Dialog as="div" class="relative z-[999]" @close="closeModal">
      <TransitionChild
        as="template"
        enter="duration-150 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-100 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-50" />
      </TransitionChild>

      <div class="fixed inset-0 flex h-full w-full overflow-auto p-4">
        <TransitionChild
          as="template"
          enter="ease-out duration-150"
          enter-from="transform translate-y-10"
          enter-to="transform translate-y-0"
          leave="ease-in duration-100"
          leave-from="transform translate-y-0"
          leave-to="transform translate-y-10"
        >
          <DialogPanel
            class="relative m-auto flex h-[620px] max-h-screen w-full max-w-[440px] bg-white shadow-xl transition-all max-md:min-h-[550px]"
          >
            <button
              type="button"
              class="absolute right-1 top-1 z-[1] flex items-center justify-center p-2"
              :aria-label="$t('close')"
              @click="closeModal"
            >
              <UiIcon name="close" class="size-6" />
            </button>
            <div
              class="h-full w-full"
              @click="contentClick"
              v-html="sitePopup?.currentTranslation?.content"
            />
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { useStorage } from "@vueuse/core";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  IStoragePopup,
  POPUPS_LOCALSTORAGE_KEY,
} from "~/plugins/site-popups.client";

const generalStore = useGeneralStore();
const router = useRouter();
const localePath = useLocalePathPolyfill();

const { sitePopup } = storeToRefs(generalStore);
const storePopups = useStorage<IStoragePopup[]>(POPUPS_LOCALSTORAGE_KEY, []);

const isOpen = ref(Boolean(sitePopup.value));

watch(sitePopup, (value) => {
  isOpen.value = Boolean(value);
});

function closeModal() {
  storePopups.value = storePopups.value.map((popup) => {
    return popup.id === sitePopup.value?.id
      ? {
          ...popup,
          last_opened_at: new Date(),
        }
      : popup;
  });

  isOpen.value = false;
}
function contentClick(e: Event) {
  const link = (e.target as HTMLElement).closest("a")?.href;
  if (link) {
    const url = new URL(link);
    if (window.location.host === url.host && !url.hash) {
      e.preventDefault();
      router.push(localePath(url.pathname + url.search));
      closeModal();
    }
  }
}
</script>
