<template>
  <nav>
    <ul>
      <li v-for="item in navigation" :key="item.id">
        <component
          :is="item.is_title ? 'div' : NuxtLink"
          :to="localePath(item.url)"
        >
          {{ item?.currentTranslation?.title }}
        </component>
        <ul v-if="item.children?.length">
          <li v-for="subItem in item.children" :key="subItem.id">
            <component
              :is="subItem.is_title ? 'div' : NuxtLink"
              :to="localePath(subItem.url)"
            >
              {{ subItem?.currentTranslation?.title }}
            </component>
            <ul v-if="subItem.children?.length">
              <li
                v-for="subItemChild in subItem.children"
                :key="subItemChild.id"
              >
                <component
                  :is="subItemChild.is_title ? 'div' : NuxtLink"
                  :to="localePath(subItemChild.url)"
                >
                  {{ subItemChild?.currentTranslation?.title }}
                </component>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { NuxtLink } from "#components";

const { headerMenu } = useGeneralStore();
const localePath = useLocalePathPolyfill();
const navigation = computed(() => {
  return toTree(headerMenu);
});
</script>
