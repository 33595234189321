export const useGoToSearchPage = () => {
  const route = useRoute();
  const router = useRouter();
  const localePath = useLocalePathPolyfill();
  async function goToSearchPageHandler(searchValue: string) {
    if (!searchValue) {
      return;
    }
    const searchPageUrl = "jewelry";
    const routeSlug =
      route.params.slug && Array.isArray(route.params.slug)
        ? route.params.slug?.[0]
        : route.params.slug;
    const routeQuery = routeSlug === searchPageUrl ? route.query : {};
    await router.push(
      localePath({
        path: `/${searchPageUrl}`,
        query: {
          ...routeQuery,
          search: searchValue,
          page: 1,
        },
      }),
    );
  }
  return {
    goToSearchPageHandler,
  };
};
